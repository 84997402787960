// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-en-jsx": () => import("../src/pages/about.en.jsx" /* webpackChunkName: "component---src-pages-about-en-jsx" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-business-en-jsx": () => import("../src/pages/business.en.jsx" /* webpackChunkName: "component---src-pages-business-en-jsx" */),
  "component---src-pages-business-jsx": () => import("../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-control-en-jsx": () => import("../src/pages/control.en.jsx" /* webpackChunkName: "component---src-pages-control-en-jsx" */),
  "component---src-pages-control-jsx": () => import("../src/pages/control.jsx" /* webpackChunkName: "component---src-pages-control-jsx" */),
  "component---src-pages-index-en-jsx": () => import("../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partner-en-jsx": () => import("../src/pages/partner.en.jsx" /* webpackChunkName: "component---src-pages-partner-en-jsx" */),
  "component---src-pages-partner-jsx": () => import("../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */)
}

